<template>
    <v-dialog :value="true" overlay-opacity="0.6" overlay-color="#000" @input="close()" max-width="800px" id="notes-examples">
        <v-card>
            <v-card-title class="py-2 dcard-title">
                <h3 class="headline mb-0 primary--text text-center"><span class="hidden-sm-and-down">Emergency </span>Notes Examples</h3>
                <v-btn absolute right icon @click.native="close"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="pa-0 pb-2">
                <v-divider />
                <v-container pa-0 pt-0 pb-2 px-3>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-expansion-panels class="mt-3 no-print" id="alert-notes">
                                <template v-for="item in alertUser.notes">
                                    <v-expansion-panel :key="item.uuid" @click="preview=null">
                                        <v-expansion-panel-header class="pl-3" @click="scrollToNote()">
                                            <span class="font-weight-medium secondary--text title"><v-icon color="primary" class="mr-1 alert-note-icon">{{ getIcon(item.type) }}</v-icon> {{ getType(item.type) }}</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <v-divider />
                                            <NoteDemo :note="item" :name="firstName" class="mt-1 mb-0" />
                                            <v-card class="elevation-2 mb-0" v-if="item.files.length">
                                                <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1"><v-icon color="primary" x-small class="mr-0 d-none">fa fa-paperclip</v-icon> This note has {{ item.files.length }} attachment{{ (item.files.length == 1) ? '' : 's' }}:</v-card-title>
                                                <v-divider />
                                                <v-card-text class="py-1">
                                                    <v-list class="pt-0 pb-0">
                                                        <template v-for="(file, idx) in item.files">
                                                            <v-list-item :key="file.uuid" :class="(item.files.length==1) ? 'pl-0 delevation-2' : 'pl-0'" @click="preview=idx">
                                                                <v-list-item-avatar size="100" tile class="elevation-3">
                                                                    <v-img v-if="file.type == 1" cover class="secondary" max-width="100" max-height="100" :src="file.file" />
                                                                    <div v-if="file.type==2" style="width: 100%;" class="grey lighten-3 text-center pt-3 py-md-5">
                                                                        <v-icon :size="($vuetify.breakpoint.smAndDown) ? 50 : 80" color="primary" class="mt-1">fa fa-file-pdf</v-icon>
                                                                    </div>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content>
                                                                    <v-list-item-title class="secondary--text body-2 text-center text-md-left">{{ file.caption || file.name }}</v-list-item-title>
                                                                </v-list-item-content>
                                                                <v-list-item-action class="hidden-sm-and-down">
                                                                    <v-btn :href="file.file" small target="_blank" outlined color="primary">View</v-btn>
                                                                </v-list-item-action>
                                                            </v-list-item>
                                                        </template>
                                                    </v-list>
                                                </v-card-text>
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </template>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="card-actions secondary">
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="px-10" @click.native="close()">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {

    name: 'HomeNotesPreview',
    props: {
    },
    computed: {
    },
    components: {
        NoteDemo: () => import('@/components/alert/NoteDemo'),
    },
    data: () => ({
        alertUser: {
            notes: [
                { uuid: '234', type: 1, body: '{}', files:[] },
                { uuid: '334', type: 2, body: '{}', files:[] },
                { uuid: '434', type: 3, body: '{}', files:[] },
                { uuid: '534', type: 4, body: '{}', files:[] },
                { uuid: '634', type: 5, body: '{}', files:[] }
            ]
        },
        firstName: 'Jane'
    }),
    methods: {
        scrollToNote(){
            setTimeout(() => {
                if(document.querySelector('.v-expansion-panel-header--active')){
                    this.$vuetify.goTo('.v-expansion-panel-header--active', {
                        container: '.v-dialog--active',
                        offset: 0
                    });
                }else{
                    this.$vuetify.goTo('.v-expansion-panels', {
                        container: '.v-dialog--active',
                        offset: 0
                    });
                }
            }, 100);
        },
        getIcon(itemType){
            if(itemType == 1){
                return 'fa fa-notes-medical';
            }else if(itemType == 3){
                return 'fa fa-dog';
            }else if(itemType == 2){
                return 'fa fa-users';
            }else if(itemType == 4){
                return 'fa fa-home';
            }

            return 'fa fa-comment-medical';
        },
        getType(itemType){
            if(itemType == 1){
                return 'Medical Notes';
            }else if(itemType == 3){
                return 'Pet Care Notes';
            }else if(itemType == 2){
                return 'Family Care Notes';
            }else if(itemType == 4){
                return 'Property Notes';
            }else if(itemType == 5){
                return 'Other Emergency Notes';
            }
        },
        getNoteDesc(idx){
            if(idx == 1){
                return 'May include medication allergies or medical conditions ' + this.firstName + ' may have.';
            }else if(idx == 2){
                return 'Actions that may be needed for those left in their care.';
            }else if(idx == 3){
                return 'Instructions for feeding and caring for their pets.';
            }else if(idx == 4){
                return 'Notes for accessing and caring for their home and plants.';
            }

            return 'Notes that ' + this.firstName + ' asked us to share with you.';
        },
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
    },
    watch: {
    }
}
</script>
